import styled from 'styled-components';
import bgLeft from '../../../images/poly-left.svg';
import bgRight from '../../../images/poly-right.svg';

export default styled.div`
  padding: 25px 0 96px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  &:before {
    pointer-events: none;
    content: '';
    width: 463px;
    height: 523px;
    position: absolute;
    left: 0px;
    top: -165px;
    background-image: url(${bgLeft});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 991px) {
      left: -110px;
      top: -245px;
    }
    @media (max-width: 768px) {
      left: -125px;
      top: -325px;
    }
  }
  &:after {
    pointer-events: none;
    content: '';
    width: 406px;
    height: 629px;
    position: absolute;
    right: 0px;
    bottom: -100px;
    background-image: url(${bgRight});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 991px) {
      right: -120px;
      bottom: -190px;
    }
    @media (max-width: 768px) {
      right: -40px;
      bottom: -365px;
    }
  }
  svg {
    max-width: 188px;
    max-height: 28px;
  }
  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 54.93px;
    line-height: 110%;
    margin-top: 112px;
    margin-bottom: 32px;
    @media (max-width: 991px) {
      margin-top: 56px;
    }
    @media (max-width: 768px) {
      margin-top: 63px;
      font-size: 35.16px;
    }
  }
  .subhead {
    color: #fff;
    margin-bottom: 0;
  }
`;
