import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Link from '../../../utils/Link';
import ComponentLibHeroStyles from '../../../styles/Components/Hero/ComponentLibHeroStyles';
import Icon from '../../../styles/atoms/icons';

const ComponentLibHero = () => (
  <ComponentLibHeroStyles className="bg-charcoal">
    <Container>
      <Row className="justify-content-center">
        <Col lg={8}>
          <Link to="/">
            <Icon id="sm-light-logo" isImage />
          </Link>
          <h1>Shopmonkey Component Library</h1>
          <p className="subhead">
            The Shopmonkey component library includes all components used across
            the website.
          </p>
        </Col>
      </Row>
    </Container>
  </ComponentLibHeroStyles>
);

export default ComponentLibHero;
